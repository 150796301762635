<template>
  <div>
    <div class="prose prose-md md:prose-lg">
      <h1>Dependent Care Assistance Program</h1>
      <!-- <h3>The 2023 limit for contributions is $5,000.</h3> -->
      <router-link class="text-xl" to="/support/articles/18310279162131"
        >See DCAP Limits</router-link
      >

      <p>
        DCAP stands for Dependent Care Assistance Program and is also a Flexible
        Spending Account. An employee can use a DCAP to be reimbursed for
        employment-related expenses that allow the employee and his or her
        spouse to be “gainfully employed.” Employment-related expenses apply
        only to certain individuals.
      </p>
      <p>
        A cafeteria plan lets participants elect coverage under a DCAP with an
        annual limit of up to $5,000. They pay for that coverage with pre-tax
        salary reduction dollars. At the end of each month, the DCAP reimburses
        the dependent care expenses that occurred during the month. Assume that
        Jane elects the full $5,000 of coverage. She must reduce her taxable
        wages by $5,000 (the annual premium) to pay for the coverage. By
        participating in the DCAP, Jane would achieve tax savings but subject to
        additional factors that affect the calculation of the tax savings.
      </p>
    </div>

    <InfoDropdown title="Important features of the plan" :headerLvl="2">
      <p>
        DCAPs are not front loaded like a typical FSA, which means that the
        available funds will grow as contributions are made throughout the plan
        year. Not all daycare providers accept benefits cards, some expenses
        will have to be paid out of pocket and then reimbursed through a claim.
      </p>
      <p>
        Who qualifies as a “dependent” for which the funds can be used? DCAP
        expenses could consist of having a babysitter or day-care provider take
        care of an employee’s child (under the age of 13) while Mom and Dad are
        both working,
        <b
          >or taking care of a spouse or other tax dependent who lives with the
          employee and is incapable of self-care.</b
        >
      </p>

      <p>Some services will not qualify, such as overnight camps.</p>
    </InfoDropdown>
  </div>
</template>

<script>
import InfoDropdown from "@/components/ui/InfoDropdown.vue";

export default {
  components: {
    InfoDropdown: InfoDropdown
  }
};
</script>
